<ion-header>
    <ion-toolbar>
        <ion-button slot="start" fill="clear" color="dark" (click)="openNavigationMenu()">
            <ion-icon name="menu"></ion-icon>
        </ion-button>
        <ion-title>Übersicht</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card *ngIf="userState && userState.currentUser">
        <ion-card-header class="person">
            <div>
                <ion-card-title>{{ userState.currentUser.info.name }}</ion-card-title>
                <ion-card-subtitle>{{ userState.currentUser.info.email }}</ion-card-subtitle>
            </div>
            <img [src]="userState.currentUser.info.picture">
        </ion-card-header>
        <ion-card-content>
            <ion-card *ngFor="let pw of userState.currentUser.temporaryPasswords">
                <ion-card-header>
                    <ion-card-title>{{ pw.title }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <dl>
                        <dt>Gültig bis</dt>
                        <dd>{{ pw.validUntil | date }}</dd>
                    </dl>
                    <ion-button (click)="removeSecret(pw.title)">
                        <ion-icon name="trash-outline"></ion-icon>
                        <ion-label>Löschen</ion-label>
                    </ion-button>
                </ion-card-content>
            </ion-card>
            <ion-card *ngIf="userState.currentUser.canManageUsers">
                <ion-card-header>
                    <ion-card-title>Neues Token</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ion-label>Name des Tokens</ion-label>
                    <ion-input [(ngModel)]="secretName" placeholder="hier eintippen"></ion-input>
                    <ion-select [(ngModel)]="secretDuration">
                        <ion-select-option [value]="1">1 Tag</ion-select-option>
                        <ion-select-option [value]="7">1 Woche</ion-select-option>
                        <ion-select-option [value]="30">1 Monat</ion-select-option>
                        <ion-select-option [value]="365">1 Jahr</ion-select-option>
                    </ion-select>
                    <ion-button (click)="createToken()">Erstelle Passwort</ion-button>
                </ion-card-content>
            </ion-card>
            <ion-button (click)="logout()">logout</ion-button>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="userState?.authenticationState === 'AUTHENTICATED' && userName">
        <ion-card-header class="person">
            <div>
                <ion-card-title>{{ userName }}</ion-card-title>
                <ion-card-subtitle>{{ userMail }}</ion-card-subtitle>
            </div>
            <img [src]="userPicture">
        </ion-card-header>
        <ion-card-content>
            <h2>Hallo {{ userName }}</h2>
            <p>Du hast dich mit deinem GMail-Account angemeldet.</p>
            <p>Jetzt kannst du den Zugang beantragen. Wenn du das machst, wird dein Foto (das oben rechts), dein Name
                ({{ userName }}) und deine eMail-Adresse ({{ userMail }})
                an den Server übermittelt und dort gespeichert. Damit kann der <a href="mailto:photos@teamkoenig.ch">Administrator</a>
                dir die Fotos frei schalten.
            </p>
            <p>
                Bitte beschreibe zu welchem Team du gehörst, damit du alle Alben sehen kannst bei denen dein Team
                anwesend war
            </p>
            <ion-input #message placeholder="Name des Teams"></ion-input>
            <ion-button (click)="subscribe()">Zugang Beantragen</ion-button>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="userState?.authenticationState=='AUTHORIZATION_REQUESTED' && userName">
        <ion-card-header class="person">
            <div>
                <ion-card-title>{{ userName }}</ion-card-title>
                <ion-card-subtitle>{{ userMail }}</ion-card-subtitle>
            </div>
            <img [src]="userPicture">
        </ion-card-header>
        <ion-card-content>
            <h2>Hallo {{ userName }}</h2>
            <p>Du hast dich mit deinem GMail-Account angemeldet und den Zugang beantragt</p>
            <p>Jetzt musst du warten bis der <a href="mailto:photos@teamkoenig.ch">Administrator</a>
                dir den Zugang und die Fotos frei geschaltet hat.
            </p>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="userState?.listPendingRequests?.length>0">
        <ion-card-header>
            <ion-card-title>Neue Anträge</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-button routerLink="/process-pending-requests">{{ userState?.listPendingRequests?.length }} neue User
            </ion-button>
        </ion-card-content>
    </ion-card>
    <ion-card routerLink="/album" *ngIf="userState?.authenticationState== 'AUTHORIZED'">
        <ion-card-header>
            <ion-card-title>Photos</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <p *ngIf="totalPhotoCount===0">
                Du hast jetzt Zugang erhalten, aber dir sind noch keine Photos freigegeben. Wende dich an den <a
                    href="mailto:photos@teamkoenig.ch">Administrator</a> um Zugang zu den interessanten Photos zu
                erhalten.
            </p>
            <p *ngIf="totalPhotoCount>0 && offlinePhotoCount===0">{{ totalPhotoCount | number }} Photos</p>
            <p *ngIf="totalPhotoCount>0 && offlinePhotoCount>0">{{ totalPhotoCount | number }} Photos total,
                {{ offlinePhotoCount | number }} offline verfügbar</p>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="userState && userState.currentUser">
        <ion-card-header>
            <ion-card-title>Lokale Daten</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <dl *ngIf="estimation">
                <dt>Total verfügbar</dt>
                <dd>{{ estimation.quota | filesize }}</dd>
                <dt>Aktuell belegt</dt>
                <dd>{{ estimation.usage | filesize }} ({{ estimation.usage / estimation.quota | percent }})</dd>
            </dl>
            <ion-button (click)="cleanupCache()" color="danger">Daten löschen</ion-button>
            <ion-button (click)="syncOffline()"
                        *ngIf="!this.syncOfflineSubscription  || this.syncOfflineSubscription.closed">Offline
                herunterladen
            </ion-button>
            <ion-button *ngIf="this.syncOfflineSubscription&&!this.syncOfflineSubscription.closed"
                        (click)="cancelOfflineSync()" color="danger">Herunterladen
                abbrechen
            </ion-button>
            <p *ngIf="this.syncOfflineSubscription&&!this.syncOfflineSubscription.closed">
                <ion-label *ngIf="syncProgress?.albumName">Lade {{ syncProgress.albumName }}</ion-label>
                <ion-progress-bar *ngIf="syncProgress?.albumCount>0"
                                  [value]="(syncProgress.albumIndex+1)/(syncProgress.albumCount+1)"></ion-progress-bar>
                <ion-progress-bar *ngIf="syncProgress?.albumEntryCount>0"
                                  [value]="(syncProgress.albumEntryIndex+1)/(syncProgress.albumEntryCount+1)"></ion-progress-bar>
            </p>
        </ion-card-content>
    </ion-card>
    <ion-card *ngIf="userState?.currentUser?.canManageUsers" routerLink="/manage-users">
        <ion-card-header>
            <ion-card-title>Nutzer bearbeiten</ion-card-title>
        </ion-card-header>
    </ion-card>
    <ion-card *ngIf="userState?.currentUser?.canManageUsers" routerLink="/manage-teams">
        <ion-card-header>
            <ion-card-title>Gruppen bearbeiten</ion-card-title>
        </ion-card-header>
    </ion-card>
    <ion-card *ngIf="userState?.currentUser?.canManageUsers" (click)="updateFnchGroups()">
        <ion-card-header>
            <ion-card-title>Gruppen von Swiss Equestrian updaten</ion-card-title>
        </ion-card-header>
    </ion-card>
    <ion-card *ngIf="userState?.currentUser?.canManageUsers" routerLink="/import">
        <ion-card-header>
            <ion-card-title>Photos Importieren</ion-card-title>
        </ion-card-header>
    </ion-card>
    <ion-card *ngIf="userState?.currentUser?.canManageUsers">
        <ion-card-header routerLink="/sync">
            <ion-card-title>Photos synchronisieren</ion-card-title>
        </ion-card-header>
    </ion-card>
    <ion-card *ngIf="userState?.authenticationState== 'AUTHORIZED'">
        <ion-card-header routerLink="/presentation">
            <ion-card-title>Präsentationsfenster</ion-card-title>
        </ion-card-header>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-title>{{ version }}</ion-title>
    </ion-toolbar>
</ion-footer>
