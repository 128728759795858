import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type FieldWrapper<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Long: any;
};

export type AddKeywordMutation = {
  albumEntryId: Scalars['ID'];
  albumId: Scalars['ID'];
  keyword: Scalars['String'];
};

export type Album = {
  __typename?: 'Album';
  albumEntry?: Maybe<FieldWrapper<AlbumEntry>>;
  albumPath: Array<FieldWrapper<Scalars['String']>>;
  albumTime?: Maybe<FieldWrapper<Scalars['DateTime']>>;
  autoaddDates: Array<FieldWrapper<Scalars['DateTime']>>;
  canAccessedBy: Array<FieldWrapper<User>>;
  canAccessedByGroup: Array<FieldWrapper<Group>>;
  canAccessedByUser: Array<FieldWrapper<User>>;
  entries: Array<FieldWrapper<AlbumEntry>>;
  entryCount?: Maybe<FieldWrapper<Scalars['Int']>>;
  id: FieldWrapper<Scalars['ID']>;
  keywordCounts: Array<FieldWrapper<KeywordCount>>;
  labels: Array<FieldWrapper<Label>>;
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  titleEntry?: Maybe<FieldWrapper<AlbumEntry>>;
  version?: Maybe<FieldWrapper<Scalars['String']>>;
  zipDownloadUri?: Maybe<FieldWrapper<Scalars['String']>>;
};


export type AlbumAlbumEntryArgs = {
  entryId?: InputMaybe<Scalars['ID']>;
};

export type AlbumEntry = {
  __typename?: 'AlbumEntry';
  album?: Maybe<FieldWrapper<Album>>;
  cameraManufacturer?: Maybe<FieldWrapper<Scalars['String']>>;
  cameraModel?: Maybe<FieldWrapper<Scalars['String']>>;
  contentType?: Maybe<FieldWrapper<Scalars['String']>>;
  created?: Maybe<FieldWrapper<Scalars['DateTime']>>;
  entryUri?: Maybe<FieldWrapper<Scalars['String']>>;
  exposureTime?: Maybe<FieldWrapper<Scalars['Float']>>;
  fNumber?: Maybe<FieldWrapper<Scalars['Float']>>;
  focalLength?: Maybe<FieldWrapper<Scalars['Int']>>;
  focalLength35?: Maybe<FieldWrapper<Scalars['Int']>>;
  height?: Maybe<FieldWrapper<Scalars['Int']>>;
  id: FieldWrapper<Scalars['ID']>;
  isoSpeedRatings?: Maybe<FieldWrapper<Scalars['Int']>>;
  keywords: Array<FieldWrapper<Scalars['String']>>;
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  originalUri?: Maybe<FieldWrapper<Scalars['String']>>;
  targetHeight?: Maybe<FieldWrapper<Scalars['Int']>>;
  targetWidth?: Maybe<FieldWrapper<Scalars['Int']>>;
  thumbnailUri?: Maybe<FieldWrapper<Scalars['String']>>;
  width?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export type AlbumEntryUpdate = {
  addKeywords: Array<Scalars['String']>;
  removeKeywords: Array<Scalars['String']>;
};

export type AlbumUpdate = {
  autoadd?: InputMaybe<Array<Scalars['DateTime']>>;
  newAlbumTitle?: InputMaybe<Scalars['String']>;
  newLabels: Array<LabelInput>;
  newTitleEntry?: InputMaybe<Scalars['ID']>;
  removeLabels: Array<Scalars['String']>;
};

export enum AlbumUpdateEventType {
  Added = 'ADDED',
  Modified = 'MODIFIED',
  Removed = 'REMOVED'
}

export type AlbumUpdatedEvent = {
  __typename?: 'AlbumUpdatedEvent';
  album?: Maybe<FieldWrapper<Album>>;
  id: FieldWrapper<Scalars['ID']>;
  type?: Maybe<FieldWrapper<AlbumUpdateEventType>>;
};

export type AuthenticationId = {
  __typename?: 'AuthenticationId';
  authority?: Maybe<FieldWrapper<Scalars['String']>>;
  id?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type AuthenticationKey = {
  authority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum AuthenticationState {
  Authenticated = 'AUTHENTICATED',
  AuthorizationRequested = 'AUTHORIZATION_REQUESTED',
  Authorized = 'AUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type CommitData = {
  albumId: Scalars['ID'];
  files: Array<ImportFile>;
};

export type CommitJob = {
  __typename?: 'CommitJob';
  album?: Maybe<FieldWrapper<Album>>;
  commitJobId: FieldWrapper<Scalars['ID']>;
  currentPhase?: Maybe<FieldWrapper<CommitPhase>>;
  currentStep?: Maybe<FieldWrapper<Scalars['Int']>>;
  files: Array<FieldWrapper<ImportingFile>>;
  totalStepCount?: Maybe<FieldWrapper<Scalars['Int']>>;
};

export enum CommitPhase {
  AddFiles = 'ADD_FILES',
  Done = 'DONE',
  Ready = 'READY',
  WriteTree = 'WRITE_TREE'
}

export type CreatedTemporaryPassword = {
  __typename?: 'CreatedTemporaryPassword';
  password: FieldWrapper<Scalars['String']>;
  title: FieldWrapper<Scalars['String']>;
  validUntil: FieldWrapper<Scalars['DateTime']>;
};

export type CredentialUpgrade = {
  groupMembershipUpdates: Array<SingleGroupMembershipUpdate>;
  groupUpdates: Array<SingleGroupVisibilityUpdate>;
  userUpdates: Array<SingleUserVisibilityUpdate>;
};

export type Group = {
  __typename?: 'Group';
  canAccess: Array<FieldWrapper<Album>>;
  id: FieldWrapper<Scalars['ID']>;
  labels: Array<FieldWrapper<Label>>;
  members: Array<FieldWrapper<UserMembership>>;
  name: FieldWrapper<Scalars['String']>;
};

export type GroupMembership = {
  __typename?: 'GroupMembership';
  from?: Maybe<FieldWrapper<Scalars['DateTime']>>;
  group: FieldWrapper<Group>;
  until?: Maybe<FieldWrapper<Scalars['DateTime']>>;
};

export type GroupUpdate = {
  newLabels: Array<LabelInput>;
  newName?: InputMaybe<Scalars['String']>;
  removeLabels: Array<Scalars['String']>;
};

export type ImportFile = {
  fileId: Scalars['ID'];
  filename?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Long']>;
};

export type ImportedFile = {
  __typename?: 'ImportedFile';
  albumEntry?: Maybe<FieldWrapper<AlbumEntry>>;
  fileId: FieldWrapper<Scalars['ID']>;
};

export type ImportingFile = {
  __typename?: 'ImportingFile';
  fileId: FieldWrapper<Scalars['ID']>;
  filename: FieldWrapper<Scalars['String']>;
  size: FieldWrapper<Scalars['Int']>;
};

export type KeywordCount = {
  __typename?: 'KeywordCount';
  count: FieldWrapper<Scalars['Int']>;
  keyword: FieldWrapper<Scalars['String']>;
};

export type Label = {
  __typename?: 'Label';
  labelName: FieldWrapper<Scalars['String']>;
  labelValue: FieldWrapper<Scalars['String']>;
};

export type LabelInput = {
  labelName: Scalars['String'];
  labelValue: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  commitImport: Array<FieldWrapper<ImportedFile>>;
  createAlbum?: Maybe<FieldWrapper<Album>>;
  createGroup?: Maybe<FieldWrapper<Group>>;
  createTemporaryPassword?: Maybe<FieldWrapper<CreatedTemporaryPassword>>;
  createUser?: Maybe<FieldWrapper<User>>;
  enqueueCommit?: Maybe<FieldWrapper<CommitJob>>;
  mutate: FieldWrapper<MutationResult>;
  removeRequest?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  removeUser?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  requestAccess?: Maybe<FieldWrapper<RequestAccessResult>>;
  setAlbumVisibility?: Maybe<FieldWrapper<User>>;
  setCanManageUserFlag?: Maybe<FieldWrapper<User>>;
  updateAlbum?: Maybe<FieldWrapper<Album>>;
  updateAlbumEntry?: Maybe<FieldWrapper<AlbumEntry>>;
  updateCredentials?: Maybe<FieldWrapper<UpdateResult>>;
  updateGroup?: Maybe<FieldWrapper<Group>>;
  updateUser?: Maybe<FieldWrapper<User>>;
};


export type MutationCommitImportArgs = {
  files: Array<ImportFile>;
};


export type MutationCreateAlbumArgs = {
  path: Array<Scalars['String']>;
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
};


export type MutationCreateTemporaryPasswordArgs = {
  duration?: InputMaybe<Scalars['Long']>;
  title: Scalars['String'];
};


export type MutationCreateUserArgs = {
  id: AuthenticationKey;
};


export type MutationEnqueueCommitArgs = {
  data: CommitData;
};


export type MutationMutateArgs = {
  updates: Array<MutationData>;
};


export type MutationRemoveRequestArgs = {
  id: AuthenticationKey;
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationRequestAccessArgs = {
  comment?: InputMaybe<Scalars['String']>;
};


export type MutationSetAlbumVisibilityArgs = {
  albumId: Scalars['ID'];
  userId: Scalars['ID'];
  visible: Scalars['Boolean'];
};


export type MutationSetCanManageUserFlagArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateAlbumArgs = {
  albumId: Scalars['ID'];
  update: AlbumUpdate;
};


export type MutationUpdateAlbumEntryArgs = {
  albumEntryId: Scalars['ID'];
  albumId: Scalars['ID'];
  update?: InputMaybe<AlbumEntryUpdate>;
};


export type MutationUpdateCredentialsArgs = {
  update: CredentialUpgrade;
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['ID'];
  update?: InputMaybe<GroupUpdate>;
};


export type MutationUpdateUserArgs = {
  update?: InputMaybe<UserUpdate>;
  userId: Scalars['ID'];
};

export type MutationData = {
  addKeywordMutation?: InputMaybe<AddKeywordMutation>;
  removeKeywordMutation?: InputMaybe<RemoveKeywordMutation>;
};

export type MutationError = {
  __typename?: 'MutationError';
  albumEntryId?: Maybe<FieldWrapper<Scalars['ID']>>;
  albumId?: Maybe<FieldWrapper<Scalars['ID']>>;
  message: FieldWrapper<Scalars['String']>;
};

export type MutationResult = {
  __typename?: 'MutationResult';
  errors: Array<FieldWrapper<MutationError>>;
  modifiedEntries: Array<FieldWrapper<AlbumEntry>>;
};

export type Query = {
  __typename?: 'Query';
  albumById?: Maybe<FieldWrapper<Album>>;
  authenticationState: FieldWrapper<AuthenticationState>;
  currentUser?: Maybe<FieldWrapper<User>>;
  groupById?: Maybe<FieldWrapper<Group>>;
  listAlbums: Array<FieldWrapper<Album>>;
  listGroups: Array<FieldWrapper<Group>>;
  listPendingRequests: Array<FieldWrapper<RegistrationRequest>>;
  listUsers: Array<FieldWrapper<User>>;
  pollCommitState?: Maybe<FieldWrapper<CommitJob>>;
  previewImport?: Maybe<FieldWrapper<Album>>;
  userById?: Maybe<FieldWrapper<User>>;
};


export type QueryAlbumByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGroupByIdArgs = {
  id: Scalars['ID'];
};


export type QueryPollCommitStateArgs = {
  jobId: Scalars['ID'];
};


export type QueryPreviewImportArgs = {
  file: ImportFile;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};

export type RegistrationRequest = {
  __typename?: 'RegistrationRequest';
  authenticationId?: Maybe<FieldWrapper<AuthenticationId>>;
  data?: Maybe<FieldWrapper<UserInfo>>;
  reason?: Maybe<FieldWrapper<Scalars['String']>>;
  requestAlbum?: Maybe<FieldWrapper<Album>>;
};

export type RemoveKeywordMutation = {
  albumEntryId: Scalars['ID'];
  albumId: Scalars['ID'];
  keyword: Scalars['String'];
};

export type RequestAccessResult = {
  __typename?: 'RequestAccessResult';
  ok?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  result?: Maybe<FieldWrapper<RequestAccessResultCode>>;
};

export enum RequestAccessResultCode {
  AlreadyAccepted = 'ALREADY_ACCEPTED',
  NotLoggedIn = 'NOT_LOGGED_IN',
  Ok = 'OK'
}

export type SingleGroupMembershipUpdate = {
  from?: InputMaybe<Scalars['DateTime']>;
  groupId: Scalars['ID'];
  isMember: Scalars['Boolean'];
  until?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
};

export type SingleGroupVisibilityUpdate = {
  albumId: Scalars['ID'];
  groupId: Scalars['ID'];
  isMember: Scalars['Boolean'];
};

export type SingleUserVisibilityUpdate = {
  albumId: Scalars['ID'];
  isMember: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  albumModified?: Maybe<FieldWrapper<AlbumUpdatedEvent>>;
};

export type TemporaryPassword = {
  __typename?: 'TemporaryPassword';
  title: FieldWrapper<Scalars['String']>;
  validUntil: FieldWrapper<Scalars['DateTime']>;
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  done: FieldWrapper<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  authentications?: Maybe<Array<Maybe<FieldWrapper<AuthenticationId>>>>;
  canAccess?: Maybe<Array<Maybe<FieldWrapper<Album>>>>;
  canAccessDirect?: Maybe<Array<Maybe<FieldWrapper<Album>>>>;
  canEdit?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  canManageUsers?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  groups?: Maybe<Array<Maybe<FieldWrapper<GroupMembership>>>>;
  id: FieldWrapper<Scalars['ID']>;
  info?: Maybe<FieldWrapper<UserInfo>>;
  isEditor?: Maybe<FieldWrapper<Scalars['Boolean']>>;
  newestAlbumCanAccess?: Maybe<FieldWrapper<Album>>;
  temporaryPasswords: Array<FieldWrapper<TemporaryPassword>>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email?: Maybe<FieldWrapper<Scalars['String']>>;
  name?: Maybe<FieldWrapper<Scalars['String']>>;
  picture?: Maybe<FieldWrapper<Scalars['String']>>;
};

export type UserMembership = {
  __typename?: 'UserMembership';
  from?: Maybe<FieldWrapper<Scalars['DateTime']>>;
  until?: Maybe<FieldWrapper<Scalars['DateTime']>>;
  user: FieldWrapper<User>;
};

export type UserUpdate = {
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  isEditor?: InputMaybe<Scalars['Boolean']>;
  removeTemporaryPasswords?: InputMaybe<Array<Scalars['String']>>;
  visibilityUpdates: Array<UserVisibilityUpdate>;
};

export type UserVisibilityUpdate = {
  albumId?: InputMaybe<Scalars['ID']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type ManageAlbumUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  update: AlbumUpdate;
}>;


export type ManageAlbumUpdateMutation = { __typename?: 'Mutation', updateAlbum?: { __typename?: 'Album', id: string } | null };

export type QueryAlbumSettingsQueryVariables = Exact<{
  albumId: Scalars['ID'];
}>;


export type QueryAlbumSettingsQuery = { __typename?: 'Query', albumById?: { __typename?: 'Album', id: string, name?: string | null, autoaddDates: Array<string>, canAccessedByGroup: Array<{ __typename?: 'Group', id: string }>, canAccessedByUser: Array<{ __typename?: 'User', id: string }>, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> } | null };

export type ShowSingleMediaEditKeywordsMutationVariables = Exact<{
  albumId: Scalars['ID'];
  albumEntryId: Scalars['ID'];
  mutation: AlbumEntryUpdate;
}>;


export type ShowSingleMediaEditKeywordsMutation = { __typename?: 'Mutation', updateAlbumEntry?: { __typename?: 'AlbumEntry', id: string } | null };

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup?: { __typename?: 'Group', id: string, name: string } | null };

export type GroupEditorListAllGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupEditorListAllGroupsQuery = { __typename?: 'Query', listGroups: Array<{ __typename?: 'Group', id: string, name: string }> };

export type ImportCommitMutationVariables = Exact<{
  data: CommitData;
}>;


export type ImportCommitMutation = { __typename?: 'Mutation', enqueueCommit?: { __typename?: 'CommitJob', commitJobId: string, files: Array<{ __typename?: 'ImportingFile', fileId: string }> } | null };

export type ImportCreateAlbumMutationVariables = Exact<{
  path: Array<Scalars['String']> | Scalars['String'];
}>;


export type ImportCreateAlbumMutation = { __typename?: 'Mutation', createAlbum?: { __typename?: 'Album', id: string } | null };

export type ImportListAlbumQueryVariables = Exact<{ [key: string]: never; }>;


export type ImportListAlbumQuery = { __typename?: 'Query', listAlbums: Array<{ __typename?: 'Album', id: string, autoaddDates: Array<string>, albumPath: Array<string> }> };

export type ImportSetAutoaddMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['DateTime'];
}>;


export type ImportSetAutoaddMutation = { __typename?: 'Mutation', updateAlbum?: { __typename?: 'Album', id: string } | null };

export type PollCommitQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PollCommitQuery = { __typename?: 'Query', pollCommitState?: { __typename?: 'CommitJob', currentPhase?: CommitPhase | null, currentStep?: number | null, totalStepCount?: number | null, album?: { __typename?: 'Album', id: string } | null, files: Array<{ __typename?: 'ImportingFile', fileId: string }> } | null };

export type EditGroupLoadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditGroupLoadQuery = { __typename?: 'Query', groupById?: { __typename?: 'Group', id: string, name: string, members: Array<{ __typename?: 'UserMembership', from?: string | null, until?: string | null, user: { __typename?: 'User', id: string } }>, canAccess: Array<{ __typename?: 'Album', id: string }>, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> } | null };

export type EditGroupUpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  update: GroupUpdate;
}>;


export type EditGroupUpdateGroupMutation = { __typename?: 'Mutation', updateGroup?: { __typename?: 'Group', id: string } | null };

export type ManageGroupsCreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type ManageGroupsCreateGroupMutation = { __typename?: 'Mutation', createGroup?: { __typename?: 'Group', id: string, name: string, canAccess: Array<{ __typename?: 'Album', id: string }>, members: Array<{ __typename?: 'UserMembership', user: { __typename?: 'User', id: string } }> } | null };

export type ManageTeamsListAllGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ManageTeamsListAllGroupsQuery = { __typename?: 'Query', listGroups: Array<{ __typename?: 'Group', id: string, name: string, canAccess: Array<{ __typename?: 'Album', id: string }>, members: Array<{ __typename?: 'UserMembership', user: { __typename?: 'User', id: string } }>, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> }> };

export type EditUserDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditUserDeleteMutation = { __typename?: 'Mutation', removeUser?: boolean | null };

export type EditUserOverviewQueryVariables = Exact<{
  userid: Scalars['ID'];
}>;


export type EditUserOverviewQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: string, canManageUsers?: boolean | null, isEditor?: boolean | null, info?: { __typename?: 'UserInfo', name?: string | null, email?: string | null, picture?: string | null } | null, groups?: Array<{ __typename?: 'GroupMembership', from?: string | null, until?: string | null, group: { __typename?: 'Group', id: string } } | null> | null, canAccessDirect?: Array<{ __typename?: 'Album', id: string } | null> | null } | null, listGroups: Array<{ __typename?: 'Group', id: string, name: string }>, currentUser?: { __typename?: 'User', id: string } | null };

export type EditUserUpdateMutationVariables = Exact<{
  userid: Scalars['ID'];
  update: UserUpdate;
}>;


export type EditUserUpdateMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string } | null };

export type ManageUsersOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type ManageUsersOverviewQuery = { __typename?: 'Query', listUsers: Array<{ __typename?: 'User', id: string, info?: { __typename?: 'UserInfo', name?: string | null, email?: string | null, picture?: string | null } | null }> };

export type ListPendingRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPendingRequestsQuery = { __typename?: 'Query', listPendingRequests: Array<{ __typename?: 'RegistrationRequest', reason?: string | null, authenticationId?: { __typename?: 'AuthenticationId', authority?: string | null, id?: string | null } | null, data?: { __typename?: 'UserInfo', email?: string | null, name?: string | null, picture?: string | null } | null }> };

export type ManageUsersAcceptRequestMutationVariables = Exact<{
  authority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type ManageUsersAcceptRequestMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string } | null };

export type ManageUsersRemoveRequestMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  authority?: InputMaybe<Scalars['String']>;
}>;


export type ManageUsersRemoveRequestMutation = { __typename?: 'Mutation', removeRequest?: boolean | null };

export type CreateTokenMutationVariables = Exact<{
  title: Scalars['String'];
  duration?: InputMaybe<Scalars['Long']>;
}>;


export type CreateTokenMutation = { __typename?: 'Mutation', createTemporaryPassword?: { __typename?: 'CreatedTemporaryPassword', password: string } | null };

export type GetUserstateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserstateQuery = { __typename?: 'Query', authenticationState: AuthenticationState, currentUser?: { __typename?: 'User', id: string, canManageUsers?: boolean | null, info?: { __typename?: 'UserInfo', name?: string | null, email?: string | null, picture?: string | null } | null, temporaryPasswords: Array<{ __typename?: 'TemporaryPassword', title: string, validUntil: string }> } | null, listPendingRequests: Array<{ __typename?: 'RegistrationRequest', authenticationId?: { __typename?: 'AuthenticationId', authority?: string | null, id?: string | null } | null }> };

export type RemoveTokenMutationVariables = Exact<{
  userId: Scalars['ID'];
  update?: InputMaybe<UserUpdate>;
}>;


export type RemoveTokenMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string } | null };

export type RequestAccessMutationMutationVariables = Exact<{
  reason?: InputMaybe<Scalars['String']>;
}>;


export type RequestAccessMutationMutation = { __typename?: 'Mutation', requestAccess?: { __typename?: 'RequestAccessResult', ok?: boolean | null, result?: RequestAccessResultCode | null } | null };

export type WelcomListFetchFnchDataQueryVariables = Exact<{ [key: string]: never; }>;


export type WelcomListFetchFnchDataQuery = { __typename?: 'Query', listGroups: Array<{ __typename?: 'Group', id: string, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> }>, listAlbums: Array<{ __typename?: 'Album', id: string, name?: string | null, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> }> };

export type AlbumContentQueryVariables = Exact<{
  albumId: Scalars['ID'];
}>;


export type AlbumContentQuery = { __typename?: 'Query', albumById?: { __typename?: 'Album', id: string, name?: string | null, entryCount?: number | null, albumTime?: string | null, version?: string | null, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }>, entries: Array<{ __typename?: 'AlbumEntry', id: string, name?: string | null, entryUri?: string | null, targetWidth?: number | null, targetHeight?: number | null, created?: string | null, contentType?: string | null, keywords: Array<string>, cameraModel?: string | null, exposureTime?: number | null, fNumber?: number | null, focalLength35?: number | null, isoSpeedRatings?: number | null }> } | null };

export type GetAlbumDetailsQueryVariables = Exact<{
  albumId: Scalars['ID'];
}>;


export type GetAlbumDetailsQuery = { __typename?: 'Query', albumById?: { __typename?: 'Album', id: string, name?: string | null, entryCount?: number | null, albumTime?: string | null, version?: string | null, labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> } | null };

export type AllAlbumVersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAlbumVersionsQuery = { __typename?: 'Query', listAlbums: Array<{ __typename?: 'Album', id: string, version?: string | null }> };

export type SubscribeAlbumMutationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeAlbumMutationsSubscription = { __typename?: 'Subscription', albumModified?: { __typename?: 'AlbumUpdatedEvent', id: string, type?: AlbumUpdateEventType | null } | null };

export type SingleAlbumMutateMutationVariables = Exact<{
  updates: Array<MutationData> | MutationData;
}>;


export type SingleAlbumMutateMutation = { __typename?: 'Mutation', mutate: { __typename?: 'MutationResult', errors: Array<{ __typename?: 'MutationError', message: string }>, modifiedEntries: Array<{ __typename?: 'AlbumEntry', id: string, name?: string | null, entryUri?: string | null, targetWidth?: number | null, targetHeight?: number | null, created?: string | null, contentType?: string | null, keywords: Array<string>, cameraModel?: string | null, exposureTime?: number | null, fNumber?: number | null, focalLength35?: number | null, isoSpeedRatings?: number | null, album?: { __typename?: 'Album', id: string } | null }> } };

export type SyncCreatePasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncCreatePasswordMutation = { __typename?: 'Mutation', createTemporaryPassword?: { __typename?: 'CreatedTemporaryPassword', validUntil: string, password: string } | null };

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPermissionsQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, canManageUsers?: boolean | null, canEdit?: boolean | null } | null };

export type ListKnownCompetitorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListKnownCompetitorsQuery = { __typename?: 'Query', listGroups: Array<{ __typename?: 'Group', labels: Array<{ __typename?: 'Label', labelName: string, labelValue: string }> }> };

export type SyncQueryDataForSyncQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncQueryDataForSyncQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string } | null, listAlbums: Array<{ __typename?: 'Album', id: string, albumPath: Array<string> }> };

export type UpdateCredentitalsMutationVariables = Exact<{
  update: CredentialUpgrade;
}>;


export type UpdateCredentitalsMutation = { __typename?: 'Mutation', updateCredentials?: { __typename?: 'UpdateResult', done: boolean } | null };

export type UserEditorListAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEditorListAllUsersQuery = { __typename?: 'Query', listUsers: Array<{ __typename?: 'User', id: string, info?: { __typename?: 'UserInfo', name?: string | null, email?: string | null, picture?: string | null } | null }> };

export const ManageAlbumUpdateDocument = gql`
    mutation manageAlbumUpdate($id: ID!, $update: AlbumUpdate!) {
  updateAlbum(albumId: $id, update: $update) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageAlbumUpdateGQL extends Apollo.Mutation<ManageAlbumUpdateMutation, ManageAlbumUpdateMutationVariables> {
    document = ManageAlbumUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryAlbumSettingsDocument = gql`
    query queryAlbumSettings($albumId: ID!) {
  albumById(id: $albumId) {
    id
    name
    canAccessedByGroup {
      id
    }
    canAccessedByUser {
      id
    }
    labels {
      labelName
      labelValue
    }
    autoaddDates
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryAlbumSettingsGQL extends Apollo.Query<QueryAlbumSettingsQuery, QueryAlbumSettingsQueryVariables> {
    document = QueryAlbumSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShowSingleMediaEditKeywordsDocument = gql`
    mutation showSingleMediaEditKeywords($albumId: ID!, $albumEntryId: ID!, $mutation: AlbumEntryUpdate!) {
  updateAlbumEntry(
    albumId: $albumId
    albumEntryId: $albumEntryId
    update: $mutation
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShowSingleMediaEditKeywordsGQL extends Apollo.Mutation<ShowSingleMediaEditKeywordsMutation, ShowSingleMediaEditKeywordsMutationVariables> {
    document = ShowSingleMediaEditKeywordsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGroupDocument = gql`
    mutation createGroup($name: String!) {
  createGroup(name: $name) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGroupGQL extends Apollo.Mutation<CreateGroupMutation, CreateGroupMutationVariables> {
    document = CreateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GroupEditorListAllGroupsDocument = gql`
    query groupEditorListAllGroups {
  listGroups {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupEditorListAllGroupsGQL extends Apollo.Query<GroupEditorListAllGroupsQuery, GroupEditorListAllGroupsQueryVariables> {
    document = GroupEditorListAllGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportCommitDocument = gql`
    mutation importCommit($data: CommitData!) {
  enqueueCommit(data: $data) {
    commitJobId
    files {
      fileId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportCommitGQL extends Apollo.Mutation<ImportCommitMutation, ImportCommitMutationVariables> {
    document = ImportCommitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportCreateAlbumDocument = gql`
    mutation importCreateAlbum($path: [String!]!) {
  createAlbum(path: $path) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportCreateAlbumGQL extends Apollo.Mutation<ImportCreateAlbumMutation, ImportCreateAlbumMutationVariables> {
    document = ImportCreateAlbumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportListAlbumDocument = gql`
    query importListAlbum {
  listAlbums {
    id
    autoaddDates
    albumPath
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportListAlbumGQL extends Apollo.Query<ImportListAlbumQuery, ImportListAlbumQueryVariables> {
    document = ImportListAlbumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportSetAutoaddDocument = gql`
    mutation importSetAutoadd($id: ID!, $date: DateTime!) {
  updateAlbum(
    albumId: $id
    update: {autoadd: [$date], newLabels: [], removeLabels: []}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportSetAutoaddGQL extends Apollo.Mutation<ImportSetAutoaddMutation, ImportSetAutoaddMutationVariables> {
    document = ImportSetAutoaddDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PollCommitDocument = gql`
    query PollCommit($id: ID!) {
  pollCommitState(jobId: $id) {
    album {
      id
    }
    currentPhase
    currentStep
    totalStepCount
    files {
      fileId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PollCommitGQL extends Apollo.Query<PollCommitQuery, PollCommitQueryVariables> {
    document = PollCommitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditGroupLoadDocument = gql`
    query editGroupLoad($id: ID!) {
  groupById(id: $id) {
    id
    name
    members {
      user {
        id
      }
      from
      until
    }
    canAccess {
      id
    }
    labels {
      labelName
      labelValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditGroupLoadGQL extends Apollo.Query<EditGroupLoadQuery, EditGroupLoadQueryVariables> {
    document = EditGroupLoadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditGroupUpdateGroupDocument = gql`
    mutation editGroupUpdateGroup($id: ID!, $update: GroupUpdate!) {
  updateGroup(groupId: $id, update: $update) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditGroupUpdateGroupGQL extends Apollo.Mutation<EditGroupUpdateGroupMutation, EditGroupUpdateGroupMutationVariables> {
    document = EditGroupUpdateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageGroupsCreateGroupDocument = gql`
    mutation manageGroupsCreateGroup($name: String!) {
  createGroup(name: $name) {
    id
    name
    canAccess {
      id
    }
    members {
      user {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageGroupsCreateGroupGQL extends Apollo.Mutation<ManageGroupsCreateGroupMutation, ManageGroupsCreateGroupMutationVariables> {
    document = ManageGroupsCreateGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageTeamsListAllGroupsDocument = gql`
    query manageTeamsListAllGroups {
  listGroups {
    id
    name
    canAccess {
      id
    }
    members {
      user {
        id
      }
    }
    labels {
      labelName
      labelValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageTeamsListAllGroupsGQL extends Apollo.Query<ManageTeamsListAllGroupsQuery, ManageTeamsListAllGroupsQueryVariables> {
    document = ManageTeamsListAllGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditUserDeleteDocument = gql`
    mutation editUserDelete($id: ID!) {
  removeUser(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditUserDeleteGQL extends Apollo.Mutation<EditUserDeleteMutation, EditUserDeleteMutationVariables> {
    document = EditUserDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditUserOverviewDocument = gql`
    query editUserOverview($userid: ID!) {
  userById(id: $userid) {
    id
    canManageUsers
    isEditor
    info {
      name
      email
      picture
    }
    groups {
      from
      until
      group {
        id
      }
    }
    canAccessDirect {
      id
    }
  }
  listGroups {
    id
    name
  }
  currentUser {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditUserOverviewGQL extends Apollo.Query<EditUserOverviewQuery, EditUserOverviewQueryVariables> {
    document = EditUserOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditUserUpdateDocument = gql`
    mutation editUserUpdate($userid: ID!, $update: UserUpdate!) {
  updateUser(userId: $userid, update: $update) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditUserUpdateGQL extends Apollo.Mutation<EditUserUpdateMutation, EditUserUpdateMutationVariables> {
    document = EditUserUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageUsersOverviewDocument = gql`
    query ManageUsersOverview {
  listUsers {
    id
    info {
      name
      email
      picture
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageUsersOverviewGQL extends Apollo.Query<ManageUsersOverviewQuery, ManageUsersOverviewQueryVariables> {
    document = ManageUsersOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListPendingRequestsDocument = gql`
    query ListPendingRequests {
  listPendingRequests {
    authenticationId {
      authority
      id
    }
    data {
      email
      name
      picture
    }
    reason
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListPendingRequestsGQL extends Apollo.Query<ListPendingRequestsQuery, ListPendingRequestsQueryVariables> {
    document = ListPendingRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageUsersAcceptRequestDocument = gql`
    mutation ManageUsersAcceptRequest($authority: String, $id: String) {
  createUser(id: {authority: $authority, id: $id}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageUsersAcceptRequestGQL extends Apollo.Mutation<ManageUsersAcceptRequestMutation, ManageUsersAcceptRequestMutationVariables> {
    document = ManageUsersAcceptRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageUsersRemoveRequestDocument = gql`
    mutation ManageUsersRemoveRequest($id: String, $authority: String) {
  removeRequest(id: {id: $id, authority: $authority})
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageUsersRemoveRequestGQL extends Apollo.Mutation<ManageUsersRemoveRequestMutation, ManageUsersRemoveRequestMutationVariables> {
    document = ManageUsersRemoveRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTokenDocument = gql`
    mutation CreateToken($title: String!, $duration: Long) {
  createTemporaryPassword(title: $title, duration: $duration) {
    password
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTokenGQL extends Apollo.Mutation<CreateTokenMutation, CreateTokenMutationVariables> {
    document = CreateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserstateDocument = gql`
    query GetUserstate {
  currentUser {
    id
    canManageUsers
    info {
      name
      email
      picture
    }
    temporaryPasswords {
      title
      validUntil
    }
  }
  authenticationState
  listPendingRequests {
    authenticationId {
      authority
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserstateGQL extends Apollo.Query<GetUserstateQuery, GetUserstateQueryVariables> {
    document = GetUserstateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTokenDocument = gql`
    mutation RemoveToken($userId: ID!, $update: UserUpdate) {
  updateUser(userId: $userId, update: $update) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTokenGQL extends Apollo.Mutation<RemoveTokenMutation, RemoveTokenMutationVariables> {
    document = RemoveTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestAccessMutationDocument = gql`
    mutation RequestAccessMutation($reason: String) {
  requestAccess(comment: $reason) {
    ok
    result
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestAccessMutationGQL extends Apollo.Mutation<RequestAccessMutationMutation, RequestAccessMutationMutationVariables> {
    document = RequestAccessMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WelcomListFetchFnchDataDocument = gql`
    query welcomListFetchFnchData {
  listGroups {
    id
    labels {
      labelName
      labelValue
    }
  }
  listAlbums {
    id
    name
    labels {
      labelName
      labelValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WelcomListFetchFnchDataGQL extends Apollo.Query<WelcomListFetchFnchDataQuery, WelcomListFetchFnchDataQueryVariables> {
    document = WelcomListFetchFnchDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AlbumContentDocument = gql`
    query AlbumContent($albumId: ID!) {
  albumById(id: $albumId) {
    id
    name
    entryCount
    albumTime
    version
    labels {
      labelName
      labelValue
    }
    entries {
      id
      name
      entryUri
      targetWidth
      targetHeight
      created
      contentType
      keywords
      cameraModel
      exposureTime
      fNumber
      focalLength35
      isoSpeedRatings
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlbumContentGQL extends Apollo.Query<AlbumContentQuery, AlbumContentQueryVariables> {
    document = AlbumContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAlbumDetailsDocument = gql`
    query GetAlbumDetails($albumId: ID!) {
  albumById(id: $albumId) {
    id
    name
    entryCount
    albumTime
    version
    labels {
      labelName
      labelValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAlbumDetailsGQL extends Apollo.Query<GetAlbumDetailsQuery, GetAlbumDetailsQueryVariables> {
    document = GetAlbumDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAlbumVersionsDocument = gql`
    query AllAlbumVersions {
  listAlbums {
    id
    version
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllAlbumVersionsGQL extends Apollo.Query<AllAlbumVersionsQuery, AllAlbumVersionsQueryVariables> {
    document = AllAlbumVersionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscribeAlbumMutationsDocument = gql`
    subscription SubscribeAlbumMutations {
  albumModified {
    id
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribeAlbumMutationsGQL extends Apollo.Subscription<SubscribeAlbumMutationsSubscription, SubscribeAlbumMutationsSubscriptionVariables> {
    document = SubscribeAlbumMutationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SingleAlbumMutateDocument = gql`
    mutation singleAlbumMutate($updates: [MutationData!]!) {
  mutate(updates: $updates) {
    errors {
      message
    }
    modifiedEntries {
      id
      name
      entryUri
      targetWidth
      targetHeight
      created
      contentType
      keywords
      cameraModel
      exposureTime
      fNumber
      focalLength35
      isoSpeedRatings
      album {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SingleAlbumMutateGQL extends Apollo.Mutation<SingleAlbumMutateMutation, SingleAlbumMutateMutationVariables> {
    document = SingleAlbumMutateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncCreatePasswordDocument = gql`
    mutation SyncCreatePassword {
  createTemporaryPassword(duration: 3600, title: "http-sync") {
    validUntil
    password
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncCreatePasswordGQL extends Apollo.Mutation<SyncCreatePasswordMutation, SyncCreatePasswordMutationVariables> {
    document = SyncCreatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPermissionsDocument = gql`
    query UserPermissions {
  currentUser {
    id
    canManageUsers
    canEdit
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPermissionsGQL extends Apollo.Query<UserPermissionsQuery, UserPermissionsQueryVariables> {
    document = UserPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListKnownCompetitorsDocument = gql`
    query ListKnownCompetitors {
  listGroups {
    labels {
      labelName
      labelValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListKnownCompetitorsGQL extends Apollo.Query<ListKnownCompetitorsQuery, ListKnownCompetitorsQueryVariables> {
    document = ListKnownCompetitorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncQueryDataForSyncDocument = gql`
    query SyncQueryDataForSync {
  currentUser {
    id
  }
  listAlbums {
    id
    albumPath
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncQueryDataForSyncGQL extends Apollo.Query<SyncQueryDataForSyncQuery, SyncQueryDataForSyncQueryVariables> {
    document = SyncQueryDataForSyncDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCredentitalsDocument = gql`
    mutation updateCredentitals($update: CredentialUpgrade!) {
  updateCredentials(update: $update) {
    done
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCredentitalsGQL extends Apollo.Mutation<UpdateCredentitalsMutation, UpdateCredentitalsMutationVariables> {
    document = UpdateCredentitalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserEditorListAllUsersDocument = gql`
    query userEditorListAllUsers {
  listUsers {
    id
    info {
      name
      email
      picture
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserEditorListAllUsersGQL extends Apollo.Query<UserEditorListAllUsersQuery, UserEditorListAllUsersQueryVariables> {
    document = UserEditorListAllUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }