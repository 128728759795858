<ion-header>
    <ion-toolbar>
        <ion-title>Navigation</ion-title>
    </ion-toolbar>
</ion-header>
<ion-list>
    <ion-item routerLink="/album" router-direction="root">
        <ion-icon name="images" slot="start"></ion-icon>
        <ion-label>Alben</ion-label>
    </ion-item>
    <ion-item routerLink="/welcome" router-direction="root">
        <ion-icon name="person-circle" slot="start"></ion-icon>
        <ion-label>Übersicht</ion-label>
    </ion-item>
    <ion-item routerLink="/import" *ngIf="userPermissions?.canEdit" router-direction="root">
        <ion-icon slot="start" name="cloud-upload"></ion-icon>
        <ion-label>Import</ion-label>
    </ion-item>
    <ion-item *ngIf="userPermissions?.canManageUsers" routerLink="/manage-users" router-direction="root">
        <ion-icon name="person-circle" slot="start"></ion-icon>
        <ion-label>Benutzer Verwalten</ion-label>
    </ion-item>
    <ion-item *ngIf="userPermissions?.canManageUsers" routerLink="/manage-teams" router-direction="root">
        <ion-icon name="people-circle" slot="start"></ion-icon>
        <ion-label>Gruppen Verwalten</ion-label>
    </ion-item>
</ion-list>
